import {useState} from 'react';
import './Gallery.css';
import '../BackgroundSlider/Slider.css'

const images = [
    {
        scr: '../images/Image1.jpg',
        alt: 'Lake house 1'
    }, {
        scr: '../images/Image2.jpg',
        alt: 'Lake house 2'
    }, {
        scr: '../images/Image3.jpg',
        alt: 'Lake house 3'
    }, {
        scr: '../images/Image2.jpg',
        alt: 'Lake house 4'
    }, {
        scr: '../images/Image3.jpg',
        alt: 'Lake house 5'
    }, {
        scr: '../images/Image3.jpg',
        alt: 'Lake house 6'
    }, {
        scr: '../images/Image2.jpg',
        alt: 'Lake house 7'
    }, {
        scr: '../images/Image1.jpg',
        alt: 'Lake house 8'
    }, {
        scr: '../images/Image2.jpg',
        alt: 'Lake house 2'
    }, {
        scr: '../images/Image3.jpg',
        alt: 'Lake house 3'
    }, {
        scr: '../images/Image2.jpg',
        alt: 'Lake house 4'
    }, {
        scr: '../images/Image3.jpg',
        alt: 'Lake house 5'
    }, {
        scr: '../images/Image3.jpg',
        alt: 'Lake house 6'
    },
];

const ImageDescribtion = [' Lake House', ' Lake House2']

const Gallery = ({isActive}) => {

    const [activeImage, setActiveImage] = useState(true);

    return (
        <div className='bgSlider'>
            <img src='../images/Image1.jpg'></img>
            <div className='overlay'></div>
            <div className='overflowSett '>
                <div className='row galleryImagesRow '>

                    {
                        images.map((img, index) => {
                            return (
                                <div className='col-sm-12 col-md-3 co-lg-3 galleryImages'>
                                    <img key={index} src={img.scr} className='selfImage'/>
                                    <p key={index} className='imageDescribtion'>{img.alt}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default Gallery;