import Header from './Header/Header';
import Slider from './BackgroundSlider/Slider';
import './App.css';
import Gallery from './Gallery/Gallery';
import { useState } from 'react';


function App() {

  const [showGallery, setShowGallery] = useState(false);
  const handleGalleryTabClick = () =>{
    setShowGallery(true)
  }
  const handleMainPageTabClick = () =>{
    setShowGallery(false);
  }

  return (
    <div className="App">
      <Header onMainPageTabClick={handleMainPageTabClick} onGalleryPageTabClick={handleGalleryTabClick}/>

      {showGallery ? <Gallery isActive={showGallery} /> : <Slider onGalleryPageTabClick={handleGalleryTabClick}/>}

      {/* <Gallery /> */}
    </div>
  );
}

export default App;
