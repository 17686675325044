import React, {useState, useEffect} from 'react';
import './Slider.css';
import Gallery from '../Gallery/Gallery';
const images = ['/images/Image1.jpg', '/images/Image2.jpg', '/images/Image3.jpg'];
const titles = ['Leak House', 'In the East'];
const imageNumbers = ['01', '02', '03'];

const ImagesBox = () => {
 return(
    <ul>
    {
        images.map((img, index) => (
            <li key={index}>
                <p className='imageBoxNumber'>{imageNumbers[index]}</p>
                <p className='imageBoxTitle'>{titles[0]}</p>
                <p className='imageBoxSeason'>{titles[1]}</p>
            </li>
        ))
    }
</ul>
 )
};

const Slider = ({
        interval = 3000, onGalleryPageTabClick
        }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [currentInterval, setCurrentInterval] = useState(interval);

    useEffect(() => {
        const changeTimer = setInterval(() => {
            setActiveIndex((prevIndex) => (
                prevIndex === images.length - 1
                    ? 0
                    : prevIndex + 1
            ));
        }, currentInterval);
        return() => clearInterval(changeTimer);
    }, [currentInterval]);

    const handleChangeInterval = (event) => {
        setCurrentInterval(Number(event.target.value));
    };


    return (
        <div className='bgSlider'>
            <div className='bgSlider'>
                <img src={images[activeIndex]}/>
            </div>

            <div className='bgSlider'>
                <div className='overlay'></div>
                <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                        <p className='season'>Spring</p>
                    </div>
                </div>
                
                <div className='row'>
                    <div className='col-sm-12 col-md-6 col-lg-6'>
                        <div className='col-sm-12 col-md-9 col-lg-9'>
                            <p className='houseLocation position-fixed'>{titles[0]}</p>
                            <p className='geoPosition position-fixed'>{titles[1]}</p>
                        </div>
                        <div className='col-sm-0 col-md-2 col-lg-2'>
                            <span className='d-inline-flex'>
                                <p className='activeNumber position-fixed'>{imageNumbers[activeIndex]}/</p>
                                <p className='totalNumber position-fixed'>03</p>
                            </span>
                        </div>
                        <div className='col-sm-0 col-md-1 col-lg-1'>
                            <ul className='position-fixed dot'>
                                {
                                    images.map((_, index) => (
                                        <li
                                            key={index}
                                            className={index === activeIndex
                                                ? 'activeDot'
                                                : 'notActiveDot'}></li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-sm-12 col-md-2 col-lg-2'>
                        <ul className='socialLogo position-fixed'>
                            <li>
                                <i className="fa-brands fa-instagram"></i>
                            </li>
                            <li>
                                <i className="fa-brands fa-facebook"></i>
                            </li>
                            <li>
                                <i className="fa-brands fa-twitter"></i>
                            </li>
                        </ul>
                    </div>
                    <div className='col-sm-12 col-md-10 col-lg-9 imageBoxes'>
                    <ul>
                        {
                            images.map((img, index) => (
                                <li key={index} className= {index === activeIndex ? 'activeImageBoxes' : ''}>
                                    <p className='imageBoxNumber'>{imageNumbers[index]}</p>
                                    <p className='imageBoxTitle'>{titles[0]}</p>
                                    <p className='imageBoxSeason'>{titles[1]}</p>
                                </li>
                            ))
                        }
                    </ul>
                    </div>
                </div>
                <p>
                    <a href='#' className='buttonViewProject' onClick={onGalleryPageTabClick}>
                        View Images
                        <i class="fa-solid fa-arrow-right"></i>
                    </a>
                </p>
            </div>
        </div>
    );
};

export default Slider;
