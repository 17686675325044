import './Header.css';


const Header = ({onMainPageTabClick, onGalleryPageTabClick }) => {
    return (
        <div className='headerStyle'>
            <ul>
                <li className='coName'>
                    <p onClick={onMainPageTabClick }>Dream House</p>
                </li>
                <li className='headerMenu'>
                    <p onClick={onGalleryPageTabClick} >Gallery</p>
                </li>

                <li className='headerMenu'>
                    <p href='#'>About us</p>
                </li>
                <li className='headerMenu'>
                    <p href='#'>Contact</p>
                </li>
            </ul>
        </div>
    )
}


export default Header;